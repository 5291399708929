<template>
  <b-img
    :src="appLogoImage"
    alt="logo"
  />
</template>
<script>
/* eslint-disable */
// prettier-ignore
import { BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg,
  },
  setup() {
    const {
      appLogoImage,
    } = $themeConfig.app

    return { appLogoImage }
  },
}
</script>
<style>
  .st0{fill:#3B1151;}
  .st1{fill:#61C2D0;}
</style>
